import * as React from 'react'
import { styled, globalCss } from '@stitches/react'
import { reset } from 'stitches-reset'

import Seo from '../components/seo'

import Logo from '../images/logo.inline.svg'

const globalStyles = globalCss(reset)

const Page = styled('div', {
  width: '100vw',
  height: '100vh',
  background: 'rgb(102,63,221)',
  background: 'linear-gradient(90deg, rgba(102,63,221,1) 0%, rgba(59,26,159,1) 100%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

const H1 = styled('h1', {
  display: 'none',
})

const Subtitle = styled('p', {
  width: '600px',
  maxWidth: '80%',
  marginTop: '24px',
  color: 'white',
  textAlign: 'center',
  fontFamily: "'Roboto', sans-serif",
})

const LogoWrapper = styled('div', {
  width: '600px',
  maxWidth: '80%',
})

const IndexPage = () => {
  globalStyles()
  return (
    <Page>
      <Seo title="Home" />
      <H1>We specialize in headless Shopify storefronts for high-volume stores</H1>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Subtitle>
        Our new website is under construction 🧩. In th meantime, you can reach us at
        hello@the7and8.com
      </Subtitle>
    </Page>
  )
}

export default IndexPage
